export enum LibraryNodeType {
    folder = "folder",
    file = "file",
    link = "link",
    activity = "activity"
}

export interface LibrarySort {
    sortBy: LibrarySortField,
    sortOrder: LibrarySortOrder
}

export enum LibrarySortField {
    name = 'name',
    notesCount = 'notesCount',
    type = 'type',
    addedDate = 'addedDate',
    fileSize = 'fileSize',
    favorite = 'favorite'
}

export enum LibrarySortOrder {
    asc = 'asc',
    desc = 'desc'
}
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup, MatTabChangeEvent, MatTab } from '@angular/material/tabs';
import { NumberToAlphabetPipe } from './../../../../common/pipes/number-to-alphabet.pipe';
import { Component, ElementRef, Input, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  FormControl,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { QuestionTypeService } from '../data-providers/question-type.service';
import { ActivityResponse, QuestionnaireService } from '../data-providers/questionnaire.service';
import { CreateQuestionnaire } from '../models/create-questionnaire.model';
import { Option } from '../models/option.model';
import { QuestionType } from '../models/question-type.model';
import { Question } from '../models/question.model';
import { RunQuestionnaire } from '../models/run-questionnaire.model';

@Component({
  selector: 'app-questionnaire-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit, OnDestroy {
  answerType: string = '';
  activityName: string = "Untitled Activity";
  activityNamenew: string = "Answer";
  isActivityNameEditing: boolean = false;
  loading: boolean = false;
  private _selectedIndex: number = 0;
  public get selectedIndex(): number {
    return this._selectedIndex;
  }
  public set selectedIndex(value: number) {
    this._selectedIndex = value;
  }
  //isactivityNamenewEditing: boolean = false;
  @Output() loadingComplete = new EventEmitter<boolean>();
  @Input('activityID') activityID: string | undefined;
  @ViewChild('activityNameView', { static: false }) activityNameView: ElementRef;
  activity: RunQuestionnaire | undefined;
  @ViewChild('stepper', { static: true }) stepper: MatTabGroup | undefined;
  questionnarireForm = this.formBuilder.group({
    questions: new FormArray([]),
  });
  questionTypes: QuestionType[] = [];
  constructor(
    private _numberToAlphabetPipe: NumberToAlphabetPipe,
    private formBuilder: FormBuilder,
    private questionTypeService: QuestionTypeService,
    private questionnaireService: QuestionnaireService,
    private _snackbar: MatSnackBar
  ) {

  }
  ngOnDestroy(): void {
    if (this.loadingComplete)
      this.loadingComplete.unsubscribe();    
  }

  get form() {
    return this.questionnarireForm.controls;
  }
  get questionGroup() {
    return this.form.questions as FormArray;
  }

  fetchQuestionTypes(): void {
    this.questionTypes = this.questionTypeService.fetchQuestionTypes();
  }

  ngOnInit(): void {
    this.fetchQuestionTypes();
    this.questionnarireForm = this.formBuilder.group({
      questions: new FormArray([]),
    });
    if (this.activityID) {
      this.fetchQuestionnaire();
    } else {
      this.addQuestion();      
      this.loadingComplete.emit(true);
    }
  }

  async fetchQuestionnaire() {

    if (!this.activityID)
      return;

    this.loading = true;
    try {
      const response: ActivityResponse = await this.questionnaireService.getActivity(this.activityID);
      if (response.activity) {
        this.activityName = response.activity.title;
        this.activity = response.activity;
        this.fillQuestionfromQuestionnarie();
      }     
      this.loading = false;
      this.loadingComplete.emit(true);
    } catch (error) {
      this.loading = false;
    }
  }

  newQuestion(question: Question | undefined = undefined): FormGroup {
    return this.formBuilder.group({
      question: [(question) ? ((question.question) ? question.question: '') : '', [Validators.required]],
      answerKey: [(question) ? ((question.answerKey) ? question.answerKey: '') : ''],
      questionType: (question) ? ((question.questionType) ? question.questionType : 'MULTIPLE_CHOICE') : 'MULTIPLE_CHOICE',
      options: new FormArray([]),
    });
  }

  newOption(label = '', isCorrectAnswer: Boolean = false, key: string): FormGroup {
    return this.formBuilder.group({
      oKey: key,
      label: [label,[Validators.required]],
      isCorrectAnswer: isCorrectAnswer,
    });
  }

  fillQuestionfromQuestionnarie() {
    if (!this.activity)
      return;
      
    this.activity.questions.map(question=>{
      this.questionGroup.push(this.newQuestion(question));
      let lastIndex = this.questionGroup.controls.length-1;
      let group = this.questionGroup.at(lastIndex) as FormGroup;
      this.addOptions(group, question?.options, question?.answers);
    });

  }

  addQuestionEvent() {
    this.validateQuestion(this.selectedIndex);
    this.addQuestion();
  }

  addQuestion() {    
    this.questionGroup.push(this.newQuestion());
    this.updateSelectedIndex();
    this.questionTypeChanged('MULTIPLE_CHOICE', this.questionGroup.controls.length-1);    
  }
  updateSelectedIndex() {
    console.log("Selected index", this.selectedIndex);
      this.selectedIndex = this.questionGroup.controls.length - 1;      
      console.log("Selected index after", this.selectedIndex);
  }

  removeQuestionEvent(index: number) {

    if (this.questionGroup.controls && this.questionGroup.controls.length > 1) {
      this.validateQuestion(this.selectedIndex);
      this.removeQuestion(index);
    }else{
      this.showSnackBar('Atleast one question is required', 'OK');
    }
  }

  removeQuestion(i: number) {
    this.questionGroup.removeAt(i);
    this.updateSelectedIndex();
  }
  
  addOptions(group:FormGroup , options: Option[], answers: string []) {
    let formArr = group.get('options') as FormArray;
    options.map(option=>{

      const filteredAnswers = answers.filter(a => (a.toLowerCase() === option.key.toLowerCase()))
      if (filteredAnswers.length > 0) {
        option.isCorrectAnswer = true;
      }
      
      this.addOptiontoGroup(formArr, option);
    });
  }

  getQuestionTypeValue(index: number) {
    let group = this.questionGroup.controls[index];
    return group.get('questionType')?.value;
  }

  getQuestionGroup(index: number) {
    let group = this.questionGroup.controls[index];
    return group as FormGroup;
  }

  getQuestionGroupErrors(index: number): string {
    let group = this.questionGroup.controls[index];
     if(group.errors) {
      return Object.keys(group.errors).length>0 ? "*" : '';
    }
    return '';
  }

  objectToArray(_object: any) {
    return Object.keys(_object);
  }
  

  getQuestionGroupOptions(index: number) {
    let group = this.questionGroup.controls[index];
    return group.get('options') as FormArray;
  }

  getQuestionFormControl(index: number) {
    let group = this.questionGroup.controls[index];
    return group.get('question');
  }

  getAnswerKeyFormControl(index: number) {
    let group = this.questionGroup.controls[index];
    return group.get('answerKey');
  }

  getQuestionGroupOption(formIndex: number, optionIndex: number) {
    let group = this.questionGroup.controls[formIndex];
    let optionArr =  group.get('options') as FormArray;
    let optionGroup = optionArr.controls[optionIndex];
    return optionGroup.get('label')
  }

  getCorrectAnswers(index: number): string {
    let group = <FormGroup>this.questionGroup.controls[index];
    let correctAnswers: string[] = [];
    let errors: any = {};
    if(group){
      let options = this.getQuestionGroupOptions(index);
      if(options && options.value.length > 0){
        options.value.map((_o: any, index: number)=>{
          if(_o.isCorrectAnswer){
            correctAnswers.push(this.getOptionKey(options, index));
          }
        })
      }
    }
    return (correctAnswers && correctAnswers.length > 0)? `[${correctAnswers.join(", ")}]`: '';
  }

  getOptionKey(optionGroup: FormArray, index: number) {
    let group = optionGroup.controls[index] as FormGroup;
    return group.controls.oKey.value;
  }

  checkIsCorrect(optionGroup: FormArray, index: number) {
    let group = optionGroup.controls[index] as FormGroup;
    return group.controls.isCorrectAnswer.value;
  }


  answerChange(value: Boolean, questionIndex: number, index: number) {
    const questionType = this.getQuestionTypeValue(questionIndex);
    let groupArr = this.getQuestionGroupOptions(questionIndex) as FormArray;
    
    if (questionType === 'MULTIPLE_CHOICE') {
      if (index < groupArr.controls.length) {
        const oGroup = groupArr.controls[index];
        let isCorrect = oGroup.get('isCorrectAnswer') as FormControl;
        isCorrect.setValue(value);
      }
    } else if (questionType === 'TRUE_OR_FALSE') {
      groupArr.controls.map((group, i) => {
        let isCorrect = group.get('isCorrectAnswer') as FormControl;
        if (value && index == i) {
          isCorrect.setValue(true);
        } else {
          isCorrect.setValue(false);
        }
      });  
    }
    
  }

  addOptiontoGroup(group: FormArray, option: Option | undefined = undefined) {

    if(group.status =='VALID'){
      let optionForm;
      if (option) {
        optionForm = this.newOption(option.value, option.isCorrectAnswer, option.key)

      } else {
        optionForm = this.newOption('', false, this._numberToAlphabetPipe.transform(group.controls.length + 1));
      }

      group.push(optionForm);
    }
   
  }

  removeOptionGroup(group: FormArray, i: number, questionIndex: number) {
    group.removeAt(i);
    this.resetOptionKeys(questionIndex);
  }

  resetOptionKeys(questionIndex: number) {
    let groupArr = this.getQuestionGroupOptions(questionIndex) as FormArray;
    groupArr.controls.map((group, i) => {
        let _key = group.get('oKey') as FormControl;
        _key.setValue(this._numberToAlphabetPipe.transform(i+1));
      });  
    
  }


  buildYesorNo() { }

  questionTypeChanged(value: string, questionIndex: number) {
    console.log(value, questionIndex);
    let optionsArr = this.getQuestionGroup(questionIndex).get(
      'options'
    ) as FormArray;
    optionsArr.clear();
    switch (value) {
      case 'MULTIPLE_CHOICE':
        optionsArr.push(this.newOption('', false, this._numberToAlphabetPipe.transform(1)));
        optionsArr.push(this.newOption('', false, this._numberToAlphabetPipe.transform(2)));
        break;
      case 'TRUE_OR_FALSE':
        optionsArr.push(this.newOption('TRUE', false, this._numberToAlphabetPipe.transform(1)));
        optionsArr.push(this.newOption('FALSE', false, this._numberToAlphabetPipe.transform(2)));
        break;
      case 'TEXTBOX':
        break;
      default:
        break;
    }
  }

  validateAndGetPostData(): any {
    
    this.validateQuestion(this.selectedIndex);
    
    if(this.questionnarireForm.status != 'VALID'){     
      return undefined;
    }


    return this.getPostData();
  }

  getErrorMessage(type: string) {
    switch (type) {
      case 'QUESTIONNARIE_FORM_VALIDATION_FAILED':
        return "Looks like there are few errors. Please fix and proceed";
      case 'INVALID_OPTION':
        return "Option cannot be empty";        
      case 'PLEASE_ADD_ATLEAST_TWO_OPTIONS':
        return 'Please add atleast two options to proceed';
      case 'CHOOSE_CORRECT_ANSWER':
        return 'Please choose atleast one correct answer';
      case 'INVALID_QUESTION':
        return 'Question cannot be empty';
      case 'INVALID_ANSWER_KEY':
        return 'Reason cannot be empty';
      default:
        return "";
    }
  }

  getPostData(): any {
     const postObj = new CreateQuestionnaire().transform(
      this.questionnarireForm.value
    );
    console.log("getPostData", postObj);
    if (this.activityID) {
      postObj.name = this.activityNameView.nativeElement.value;
    }
    postObj.title = this.activityNameView.nativeElement.value;
    
    // postObj.answerReason = this.answerType;

    return postObj;
  }
 
  validateQuestion(index: number=0) {
    console.log("Validating index: ", index);

    let group = <FormGroup>this.questionGroup.controls[index];
    let validCorrectAnswer = false;
    let errors: any = {};
    if(this.getQuestionFormControl(index)?.hasError('required')) {
      errors['invalid_question_title'] = true;
    }
    if(group){
      let options = this.getQuestionGroupOptions(index);

      
      for (let i in group.controls) {
        group.controls[i].markAsDirty()
      }
      if(options && options.value.length > 0){
        for (let i in options.controls) {
          let grp = <FormGroup>options.controls[i];
          for (let j in grp.controls) {
            grp.controls[j].markAsDirty();
          }
        }
        options.value.map((val: { label: string, isCorrectAnswer: boolean; })=>{
          if(val.isCorrectAnswer ===true){
            validCorrectAnswer = true
          }

          if (val.label === '') {
            errors['invalid_option_title'] = true;
          }
        })
        if(options.value.length <2){
          errors['invalid_options'] = true;
        }
        if(!validCorrectAnswer){
          errors['invalid_correct_answer'] = true;
        }        
      } else {
        errors['invalid_options'] = true;
      }

      if(Object.keys(errors).length>0){
        group.setErrors(errors);
      }

      console.error(errors);
    }
  }

  tabFocusChange(event: MatTabChangeEvent){
   this.validateQuestion(this.selectedIndex);

   console.log("errors", this.questionGroup.controls[this.selectedIndex].errors);
  }

  tabChange(event: MatTabChangeEvent) {
    this._selectedIndex = event.index;
  }

  showSnackBar(message: string, action: string) {
    this._snackbar.open(message, action, { duration: 10000 });
  }

}
<div class="learning-space-popup-main">
    <div class="learning-space-popup-box" fxLayout="row" fxLayoutAlign="center center">
        <div class="learning-space-popup-body w-100 pt-20">
            <div fxLayout="column">
                <ng-container *ngIf="fetchingSpace; else EditForm">
                    Loading...
                </ng-container>
                <ng-template #EditForm>
                    <form [formGroup]="editLearningSpaceForm" [ngClass]="loading || mutateInProgress? 'show-loading' : ''"
                    (ngSubmit)="onSubmit(editLearningSpaceForm.value)">
                    <div class="learning-space-popup-header-main">
                        <div class="learning-space-popup-header">
                            <span class="icon icon-close cursor-pointer" (click)="closeLearningSpaceDialog()"></span>
                            <h4 class="h4-24-black mb-10 pt-10 align-center">Edit {{displayTitle}}</h4>
                            <div class="pt-20" fxLayout="row wrap" fxLayoutWrap="wrap" fxLayoutAlign="start center">
                                <div fxFlex="100" class="pr-20" fxLayoutAlign="start center">
                                    <div fxLayout="column" class="w-100">
                                        <mat-form-field appearance="outline">
                                            <input #spaceNameInput matInput placeholder="Space Name"
                                                formControlName="spaceName" autocomplete="off"
                                                maxlength="{{spaceNameMaxLength}}" required>
                                            <span matSuffix
                                                class="black-text-suffix">{{remainingLengthForSpaceName}}</span>
                                            <mat-error
                                                *ngIf="!editLearningSpaceForm.controls['spaceName'].valid && editLearningSpaceForm.controls['spaceName'].touched">
                                                <div class="password-error">
                                                    <span class="exclamation-symbol">
                                                        <span>!</span>
                                                    </span>
                                                    <p>Space Name is required</p>
                                                </div>
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field class="mat-textarea">
                                            <textarea #descriptionTextarea matInput placeholder="Enter Description"
                                                maxlength="{{descriptionMaxLength}}"
                                                formControlName="description"></textarea>
                                            <span matSuffix
                                                class="black-text-suffix">{{remainingLengthForDescription}}</span>
                                        </mat-form-field>
                                        <app-tag-auto-complete #tagField [tags]="space && space.tags? space.tags : []" [viewMode]="false">
                                        </app-tag-auto-complete>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="learning-space-popup-select-template-main">
                            <app-choose-template (templatesLoadedEvent)="templatesLoaded()"
                                (templateSelectedEvent)="templateSelected($event)"></app-choose-template>
                        </div> -->
                    </div>
                    <div mat-dialog-actions position="bottom" class="learning-space-buttons" fxLayout="row"
                        fxLayout="center center">
                        <div class="buttons-row">
                            <button mat-raised-button type="button" class="btn-grey" (click)="closeLearningSpaceDialog()"
                                [disabled]="fetchingSpace || loading || mutateInProgress">Cancel</button>
                            <button type="submit" mat-raised-button
                                [disabled]="fetchingSpace || loading || mutateInProgress || editLearningSpaceForm.invalid"
                                class="btn-submit">Update</button>
                            <mat-spinner *ngIf="mutateInProgress" [color]="color" diameter="25" strokeWidth="3">
                            </mat-spinner>
                        </div>
                    </div>
                </form>
                </ng-template>
                
            </div>
        </div>
    </div>
</div>
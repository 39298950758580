import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AckResponse } from 'src/app/common/constants';
import { InformationDialogComponent } from 'src/app/shared/components/information-dialog/information-dialog.component';
import { SpaceService } from '../../data-providers/space.service';
import { SpaceType } from '../../helper/space.constant';

@Component({
  selector: 'app-delete-confirmation-dialog',
  templateUrl: './delete-confirmation-dialog.component.html',
  styleUrls: ['./delete-confirmation-dialog.component.scss']
})
export class DeleteConfirmationDialogComponent implements OnDestroy {

  submissionInProgress: boolean = false;
  showError: boolean = false;
  errorMessage?: string;
  spaceId: string;
  spaceTitle: string;
  spaceType: SpaceType;
  formGroup: any;
  displayTitle: string = "Learning Space";
  color: ThemePalette = 'primary';

  @Output() deleteSpaceEvent = new EventEmitter();


  constructor(
    private dialog: MatDialog,
    private _spaceService: SpaceService,
    private _snackBar: MatSnackBar) {

  }

  submitTapped(): void {
    this.deleteSpace(this.spaceId, this.spaceType);
  }

  async deleteSpace(_spaceId: string, _type: SpaceType) {
    this.submissionInProgress = true;
    this.showError = false;
    this.errorMessage = undefined;

    try {
      let response: AckResponse = await this._spaceService.deleteSpace(_spaceId, _type);
      if (response.success) {

        this.showError = false;

        this.deleteSpaceEvent.emit();

        const dialogRef = this.dialog.getDialogById('delete_conf_dialog');
        if (dialogRef)
          dialogRef.close();

        this.showSnackBar(`Your ${this.displayTitle} has been deleted.`, "OK");
      }
      else {
        this.showErrorDialog("Oops,something went wrong. Please try again");
        this.showError = true;
      }

      this.submissionInProgress = false;


    } catch (error) {
      this.submissionInProgress = false;
      this.showErrorDialog("Oops,something went wrong. Please try again");
      this.showError = true;
    }
  }

  showErrorDialog(error: any) {

    const dialogRef = this.dialog.open(InformationDialogComponent, {
      width: '300px',
      id: 'information_dialog'
    })

    dialogRef.componentInstance.content = error
  }

  showSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 10000 });
  }


  ngOnDestroy(): void {

    if (this.deleteSpaceEvent)
      this.deleteSpaceEvent.unsubscribe();

  }

}

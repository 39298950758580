import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LibraryNode } from './../main/library/models/library-node.model';
import {LibraryNodeType} from './../main/library/helper/library.constant';
import { LibraryListResponse, LibraryNodeResponse, LibraryService } from './../main/library/data-providers/library.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewFolderDialogComponent } from './../main/library/components/new-folder-dialog/new-folder-dialog.component'
import { FormsModule } from '@angular/forms';
import { I } from '@angular/cdk/keycodes';


@Component({
  selector: 'app-browser',
  templateUrl: './browser.component.html',
  styleUrls: ['./browser.component.scss']
})
export class BrowserComponent implements OnInit {
  private paramSubscription?: Subscription;
  public tabUrl: string;
  private currentFolder: string;
  public previousFolder: string;
  errorMessage?: string;
  isButtonDisabled : boolean = false;
  errorMessageButtonTitle: string = "Retry";
  fetchingNodes: boolean = false;
  savingUrlStatus: boolean = false;
  showError: boolean = false;
  nodes?: LibraryNode[];
  isShowWelcomeCard: boolean = false;
  parentNodeID: string | undefined;
  isSaveCompleted:boolean = false;
  showInputBox:boolean= false;
  folderTitle:string ='';
  parentTitle:string = '';
  noNodes:boolean = false;
  stringLength: number =60;
  constructor(private router: Router,private route: ActivatedRoute,private libraryService: LibraryService,private _dialog: MatDialog) { 
  
  }
  
  ngOnInit(): void {
    this.previousFolder = "";
    this.paramSubscription = this.route.paramMap.subscribe((params: ParamMap) => {

      if (this.router.url.indexOf('/browser') !== -1) {
          const url = params.get('url')
          if (url) {
              this.tabUrl = decodeURIComponent(url);
            
          }
      }
      else {
          this.tabUrl = "";
      }
    // this.noNodes = true;
      this.getLibrary(this.currentFolder,0);

  });
  }
  getFaviconUrl(turl:string){
    const url = new URL(
        turl
      );
      
      return "https://www.google.com/s2/favicons?domain="+url.hostname;
  }
  trimUrl(turl:string){
      if(turl.length > 34){
        return turl.substring(0, 34)+"..."
      }
      return turl;
  }
  async selectBackFolder(){
    //console.log(this.previousFolder),"previous"
    //console.log( this.previousFolder," this.previousFolder;")
    this.currentFolder = "";
    this.getLibrary(this.currentFolder,true)
  }
  async selectParent(node: LibraryNode){
   this.parentTitle = node.title;
   this.previousFolder = this.currentFolder;
   console.log("Parent Folder:",this.previousFolder)
   this.currentFolder = node.id;
   this.getLibrary(node.id,0)
  }  
  async saveUrl(){
    this.savingUrlStatus = true;
    this.isButtonDisabled = true;
    try {
        let response: LibraryListResponse = await this.libraryService.createNode(LibraryNodeType.link,undefined,this.currentFolder,this.tabUrl);
        if (response.success) {
            this.isSaveCompleted = true;
            this.getLibrary(this.currentFolder,0);
        }
        this.savingUrlStatus = false;
        this.isButtonDisabled = false;
    }
    catch (error) {
        
        this.savingUrlStatus = false;
        this.errorMessage = "Oops,something went wrong. Please try again";
        this.showError = true;
        this.errorMessageButtonTitle = "Retry";
        this.isButtonDisabled = false;
    }
  }

  createDialog() {
    this.showInputBox=false; 
    this.createFolder(this.folderTitle);
    //  add logic here
    
    
  }
  showInput(){   

    this.folderTitle="";
    this.showInputBox = true;
  }
    cancelInputBox(){
      this.showInputBox = false;
    }
  // folder popup
  newFolderTapped() {
        const thisReference = this;
        const dialogRef = this._dialog.open(NewFolderDialogComponent, {
            width: '600px',
            id: "new_folder_dialog",
            data: { parentNode: this.currentFolder }
        });

        const dialogResultSubscription = dialogRef.componentInstance.folderCreatedEvent.subscribe(result => {
            //console.log('Got the event from create folder popup', result);
            dialogResultSubscription.unsubscribe();

            this.getLibrary(this.currentFolder,0)
        });


    }
  async getLibrary(parent: string | undefined, isBack : boolean | 0) {
    this.nodes = [];
    this.fetchingNodes = true;
    this.showError = false;
    this.errorMessage = undefined;
    this.showInputBox = false;

    try {
        let response: any = await this.libraryService.getNodesFolder(parent);
        //console.log(response,"responseresponseresponse-Get Library")
        
        if (response.success) {
            this.nodes = response.nodes;
            let parentId : any = "";
            if(this.nodes == undefined){
                this.noNodes = true;

            }else{
              this.noNodes = false;
            }
            parentId = response.parent;
            if(parentId){
              /*if(isBack){
                this.parentTitle = "loading..";
                  let parent: LibraryListResponse = await this.libraryService.getNodesFolder(parentId);
                  if (parent.success) {
                    if(parent.nodes){
                      this.parentTitle = parent.nodes[0].title;
                    }
                    
                  }
                  else{
                    this.parentTitle = "";
                  }
              }*/
              this.parentTitle  = response.parentTitle;
              
                this.previousFolder = parentId;
                //console.log(this.previousFolder,"this.previousFolder - getLibrary")
            }
            if(parentId == ""){
              this.parentTitle = "";
            }
            
            
            if (this.nodes && Array.isArray(this.nodes) && this.nodes.length > 0) {

                this.showError = false;
            }
            else {

                if (!this.parentNodeID) {
                    this.isShowWelcomeCard = true;
                    this.errorMessage = undefined;
                    this.showError = false;
                }
                else {
                    this.errorMessageButtonTitle = "Refresh";
                    this.errorMessage = "No files/links/folders available";
                    this.showError = true;

                }


            }



        }
        else {
            this.errorMessage = "Oops,something went wrong. Please try again";
            this.showError = true;
            this.errorMessageButtonTitle = "Retry";
        }

        this.fetchingNodes = false;


    } catch (error) {
        this.nodes = []
        this.fetchingNodes = false;
        this.errorMessage = "Oops,something went wrong. Please try again";
        this.showError = true;
        this.errorMessageButtonTitle = "Retry";
    }
}



async createFolder(folderTitle: string) {
    this.showError = false;
    this.errorMessage = undefined;
    this.isButtonDisabled = true;
//console.log(folderTitle,"FolderTiltlt")
    try {
      let response: LibraryNodeResponse = await this.libraryService.createNode(LibraryNodeType.folder, folderTitle,  this.currentFolder);
     // console.log(response,"responseresponseresponse")
      if (response.success) {
        const receivedNode: LibraryNode | undefined = response.node;

        if (receivedNode) {

          this.showError = false;
          this.getLibrary(this.currentFolder,0)
        }
        else {
          this.showError = true;
        }
      }
      else {
        this.showError = true;
      }
      this.isButtonDisabled = false;


    } catch (error) {
      console.log("Unable to create folder", error);
      this.isButtonDisabled = false;
      this.showError = true;
    }
  }

  lengthCalc(){
    this.stringLength =60-this.folderTitle.length;
  }

}

import { Injectable } from "@angular/core";
import { Question } from "./question.model";
import { Option, OptionAdapter } from "./option.model";
import { Adapter } from "src/app/common/interfaces/adapter";

export class RunQuestionnaire {
  id: string;
  title: string;
  questions: Question[] = [];


  resetAnswers() {
    this?.questions.map(q => {
        q.options.map(o =>{
          o.isAnswered = false;
          o.isCorrectAnswer = undefined;
        })
  
        q.isAnswered = false;
        q.isCorrectAnswer = undefined;
      })
  }

  // transform(data: any): RunQuestionnaire {
  //     let questionnaire = new RunQuestionnaire();
  //     data.questions.map((question: { question: string; questionType: string; options: any[]; })=>{
  //         let questionObj = new Question();
  //         questionObj.question = question.question;
  //         questionObj.questionType = question.questionType;
  //         if(question.questionType != 'TEXTBOX'){
  //             question.options.map((option: { label: string; isCorrectAnswer: Boolean; answer: string;})=>{
  //                 let optionObj = new Option();
  //                 optionObj.label = option.label;
  //                 optionObj.value = option.label;
  //                 questionObj.options.push(optionObj);
  //                 if(option.isCorrectAnswer){
  //                     questionObj.answer = option.label;
  //                 }
  //             });
  //         }
  //         questionnaire.questions.push(questionObj);
  //     });
  //     return questionnaire;
  // }
}

@Injectable({
  providedIn: "root",
})
export class RunQuestionnaireAdapter implements Adapter<RunQuestionnaire> {
  adapt(item: any): RunQuestionnaire {
    let questionnaire = new RunQuestionnaire();

    questionnaire.id = item.id;
    questionnaire.title = item.name;

    if (item.questions) {
      item.questions.map((question: any) => {
        let questionObj = new Question();
        questionObj.question = question.description;
        questionObj.question = questionObj.question.replace(
          "#BLANK",
          "................"
        );
        questionObj.questionType =
          question.type == "MCQ" ? "MULTIPLE_CHOICE" : "TRUE_OR_FALSE";
        questionObj.answerKey = question.answerReason.replace(
          new RegExp("\r?\n", "g"),
          "<br>"
        );
   ;
        if (
          question.answer &&
          Array.isArray(question.answer) &&
          question.answer.length > 0
        ) {
          questionObj.answers = question.answer;
        }
        if (question.options) {
          question.options.map((option: any) => {
            const _optionAdapter = new OptionAdapter();
            const optionObj = _optionAdapter.adapt(option);
            questionObj.options.push(optionObj);
          });
        }
        questionnaire.questions.push(questionObj);
      });
    }
    return questionnaire;
  }
}

import { UserProfile } from "./../models/user-profile.model";
import {
  Component,
  OnInit,
  Input,
  Inject,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { UserProfileService } from "../data-providers/user-profile.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { InformationDialogComponent } from "src/app/shared/components/information-dialog/information-dialog.component";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Avatar } from "../models/avatar.model";

@Component({
  selector: "app-profile-image",
  templateUrl: "./profile-image.component.html",
  styleUrls: ["./profile-image.component.scss"],
})
export class ProfileImageComponent implements OnInit {
  @Input() userDetails: UserProfile;
  @Input() userID: string | undefined;
  @Input() viewMode = true;
  @Input() avatarMode = false;
  @Input() avatarSize = "small";
  file: File;
  uploading: boolean = false;

  constructor(
    private _profileService: UserProfileService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  async profileImageUpload(event: any) {
    this.uploading = true;

    if (event.target.files.length > 0) {
      const supportedExtensions = ["jpg", "jpeg", "bmp", "png"];
      this.file = event.target.files[0];
      const fileSplit = this.file.name.toLowerCase().split(".");
      const fileExtension = fileSplit[fileSplit.length - 1];
      if (supportedExtensions.indexOf(fileExtension) < 0) {
        this.showErrorDialog("Only jpg, jpeg, bmp and png formats supported");
        this.uploading = false;
      } else {
        let fileSize = Math.round(this.file.size / 1024);
        if (fileSize < 5120) {
          try {
            let response = await this._profileService.uploadProfilePicture(
              this.file,
              undefined,
              this.userID
            );

            if (response.success && response.profilePictureImageurl) {
              this.showSnackBar("Profile image has been uploaded", "OK");
              const imageUrl = response.profilePictureImageurl;
              const avatarId = response.avatarId;
              this.userDetails.updateProfilePicture(imageUrl, avatarId);
            } else {
              this.showErrorDialog(
                "Oops,something went wrong. Please try again"
              );
            }

            this.uploading = false;
          } catch (error) {
            this.showErrorDialog("Oops,something went wrong. Please try again");
            this.uploading = false;
          }
        } else {
          this.showErrorDialog("File size should be below 5 MB");
          this.uploading = false;
        }
      }
    } else {
      this.showErrorDialog("Oops,something went wrong. Please try again");
      this.uploading = false;
    }
  }

  async uploadAvatar(_avatar: Avatar, completionCallback: () => void) {
    this.uploading = true;

    try {
      _avatar.loading = true;
      let response = await this._profileService.uploadProfilePicture(
        undefined,
        _avatar.id,
        this.userID
      );

      if (response.success && response.profilePictureImageurl) {
        this.showSnackBar("Avatar has been applied", "OK");
        const imageUrl = response.profilePictureImageurl;
        const avatarId = response.avatarId;
        this.userDetails.updateProfilePicture(imageUrl, avatarId);

        completionCallback();
      } else {
        this.showErrorDialog("Oops,something went wrong. Please try again");
      }

      this.uploading = false;
      _avatar.loading = false;
    } catch (error) {
      this.showErrorDialog("Oops,something went wrong. Please try again");
      this.uploading = false;
      _avatar.loading = false;
    }
  }

  async avatarSelected(_avatar: Avatar) {
    this.uploadAvatar(_avatar, () => {
      console.log("Upload completed");
    });
  }

  showSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 10000 });
  }

  showErrorDialog(error: any) {
    const dialogRef = this.dialog.open(InformationDialogComponent, {
      width: "400px",
      id: "information_dialog",
    });

    dialogRef.componentInstance.content = error;
  }

  async removePicture() {
    this.uploading = true;

    try {
      let response = await this._profileService.removeProfilePicture(
        this.userID
      );
      console.log(response, "response");
      if (response.success) {
        this.showSnackBar("Profile picture deleted successfully.", "OK");
        this.userDetails.clearProfilePicture();
      } else {
        this.showErrorDialog("Unable to remove profile picture");
      }
      this.uploading = false;
    } catch (error) {
      this.showErrorDialog("Oops,something went wrong. Please try again");
      this.uploading = false;
    }
  }

  chooseAvatar() {
    const dialogRef = this.dialog.open(ChooseAvatarDialog, {
      width: "380px",
      height: "80vh",
      data: { userDetails: this.userDetails },
    });

    const thisReference = this;

    const dialogResultSubscription =
      dialogRef.componentInstance.avatarSelectedEvent.subscribe(
        (_avatar: Avatar) => {
          console.log("Got the event from avatar popup");
          dialogResultSubscription.unsubscribe();

          thisReference.uploadAvatar(_avatar, () => {
            console.log("Upload completed. Closing dialog...");
            dialogRef.close();
          });
        }
      );
  }
}

@Component({
  selector: "choose-avatar-dialog",
  templateUrl: "choose-avatar-dialog.html",
})
export class ChooseAvatarDialog implements OnDestroy {
  userDetails: UserProfile;

  avatarSelectedEvent = new EventEmitter<Avatar>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _profileService: UserProfileService
  ) {
    this.userDetails = data.userDetails;
  }
  ngOnDestroy(): void {
    if (this.avatarSelectedEvent) {
      console.log("Unsubscribing to avatar selected event");
      this.avatarSelectedEvent.unsubscribe();
    }
  }

  avatarSelected(_avatar: Avatar) {
    this.avatarSelectedEvent.emit(_avatar);
  }
}

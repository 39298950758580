import { THEME } from './../../../../common/constants';
import { LibraryNodeResponse, LibraryService } from './../../data-providers/library.service';
import { LibraryNode } from './../../models/library-node.model';
import { LibraryNodeType } from '../../helper/library.constant';
import { Component, Inject, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '../../../../common/service/common.service';
import { Subscription } from 'rxjs';
import { SpaceType } from 'src/app/main/spaces/helper/space.constant';
import { Router } from '@angular/router';
import { E } from '@angular/cdk/keycodes';


export interface NodeViewerDialogData {
  readonly?: boolean,
  node: LibraryNode,
  nodes: LibraryNode[];
  currentIndex: number;
  space?: any;
  path?: any;
  id?: any;
  pathId?: any;
  singleNode?:boolean
}


@Component({
  selector: 'app-library-file-viewer-dialog',
  templateUrl: './library-file-viewer-dialog.component.html',
  styleUrls: ['./library-file-viewer-dialog.component.scss']
})
export class LibraryFileViewerDialogComponent implements OnDestroy {
  loadingwrap: boolean = false;
  path: any;
  pathId: any;
  fetchingPath: boolean = false;
  tempSpaceId: any;
  nodes: LibraryNode[];
  node: LibraryNode;
  currentIndex: number = 0;
  nodeType = LibraryNodeType;
  loading = false;
  isDarkMode: boolean = true;
  readonly: boolean = false;
  classNames:string="learning-flex dailogFull"
  themeSubscription: Subscription;
  @Output() nodeChangeEvent = new EventEmitter<{ node: LibraryNode, action: string }>();
  deleteNodeSubscription: Subscription;
  space: any;
  _space: any;
  typeEnum = SpaceType;
  setIndex:number;
  nodeSingle: boolean=false;
  constructor(
    public libraryService: LibraryService,
    private _router: Router,
    public dialogRef: MatDialogRef<LibraryFileViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NodeViewerDialogData,
    private _commonService: CommonService,

  ) {

    this.space = data.space
    this._space = data.space
    this.nodes = data.nodes;

  this.nodeSingle=data.singleNode ? true : false

  if (data.pathId) {
      this.pathId = data.pathId
    }

    this.currentIndex = data.currentIndex;
    if (data.readonly) {
      this.readonly = data.readonly;
    }
    if (data.path) {
      this.path = data.path

    }
    if (data.id) {
      this.tempSpaceId = data.id
    }

    if (this.currentIndex !== -1 && this.currentIndex < data.nodes.length) {
      this.node = this.nodes[this.currentIndex];
      this.setIndex=this.currentIndex
      
    }
    else {
      this.currentIndex = 0;
      this.node = this.nodes[0];
    }

    this.setNode(this.node);

  }
  ngOnDestroy(): void {

    if (this.nodeChangeEvent) {
      this.nodeChangeEvent.unsubscribe();
    }

    if (this.deleteNodeSubscription)
      this.deleteNodeSubscription.unsubscribe();
  }
  
  showNode(e:any)
  {

this.currentIndex=e;
const node = this.nodes[this.currentIndex];
this.setNode(node);


  }

  ngOnInit() {
// console.log(this.nodes.length)
// this.currentIndex=2;
// const node = this.nodes[this.currentIndex];
// this.setNode(node);

    this.themeSubscription = this._commonService.getThemeSubject().subscribe(_theme => {

      if (_theme) {
        this.isDarkMode = (_theme === THEME.THEME_DARK ? true : false);

      //  console.log(this.isDarkMode)

        if(this.isDarkMode)
        {
        this.classNames="learning-flex dailogFull dark-theme"
        } else
        {
          this.classNames="learning-flex dailogFull"
        }





      }

    });

    this.deleteNodeSubscription = this._commonService.getDeleteNodeSubject().subscribe(nodeObject => {
      if (nodeObject) {
        this.removeNode(nodeObject);
      }
    })

  }

  async setNode(_node: LibraryNode) {


    this.node = _node;
    this.loading = true;

    // Download content for link type
    if (_node && _node.type == LibraryNodeType.link) {

      try {
        this.node = await this.getNodeDetail(_node.id)
      } catch (error) {
      }
    }

    this.loading = false;


  }

  openLink() {

    let url: string = '';
    if (!/^http[s]?:\/\//.test(this.node.urlInfo.url)) {
      url += 'https://';
    }

    url += this.node.urlInfo.url;

    window.open(url);


  }

  async getNodeDetail(nodeId: string): Promise<LibraryNode> {

    return new Promise(async (resolve, reject) => {

      try {
        let response: LibraryNodeResponse = await this.libraryService.getNode(nodeId);

        if (response.success && response.node) {
          resolve(response.node);
          return;
        }
        reject(null);
        return;

      } catch (error) {
        reject(null);
        return;
      }

    })


  }

  // get viewer(): viewerType {
  //   return "google";
  // }

  // documentViewerType(): ViewerType {

  //   if (this.node.fileInfo.viewer.viewerSource) {
  //     const viewerSource = this.node.fileInfo.viewer.viewerSource;

  //     return viewerSource as ViewerType;


  //   }
  //   return "google";


  // }

  toggleDarkMode() {
    this.isDarkMode = !this.isDarkMode;
    if(this.isDarkMode)
    {
    this.classNames="learning-flex dailogFull dark-theme"
    } else
    {
      this.classNames="learning-flex dailogFull"
    }
  }

  updatePreviousNextUI() {

  }

  previous() {

    if ((this.currentIndex > 0) && (this.currentIndex - 1) < this.nodes.length) {

      this.currentIndex -= 1;
      
      const node = this.nodes[this.currentIndex];
      this.setNode(node);

this.setIndex=this.currentIndex;
    }

  }

  get canShowPrevious() {
    if ((this.currentIndex > 0) && (this.currentIndex - 1) < this.nodes.length) {
      return true;
    }
    else {
      return false;
    }
  }

  get canShowNext() {

    if ((this.currentIndex + 1) < this.nodes.length) {
      return true;
    }
    return false;

  }

  next() {

    if ((this.currentIndex + 1) < this.nodes.length) {

      this.currentIndex += 1;
      const node = this.nodes[this.currentIndex];
      this.setNode(node);
      this.setIndex=this.currentIndex;
    }


  }

  removeNode(node: LibraryNode) {
    if (!this.nodes) {
      return;
    }

    node.removed = true;

    const nodeIndex = this.nodes.indexOf(node);
    if (nodeIndex > -1) {
      this.nodes = this.nodes.filter(n => !n.removed)
    }

    if (this.nodes.length == 0) {
      this.dialogRef.close();
    }
    else if (this.nodes.length == 1) {
      this.previous();
    }
    else {
      this.next();
    }

    if (this.canShowNext) {
      this.next();
    }
    else if (this.canShowPrevious) {
      this.previous();
    }
    else {
      if (this.nodes.length > 0) {
        this.currentIndex = 0;
        this.node = this.nodes[0];
      }
      else {
        this.dialogRef.close();
      }

    }

  }


  onCloseClick(): void {
    this.dialogRef.close();
  }


  previousSpaceEvent(currentSpace?: any) {
    this.loadingwrap = true;

    if (!this.path || !this.path.spacesMeta) {
      return;
    }

    if (!this.path.hasPreviousSpace(currentSpace)) {
      console.error("No previous space to navigate");
      return;
    }

    const spaceItem = this.path.spacesMeta[this.path.spaceMetaIndex(currentSpace) - 1];

    if (spaceItem && spaceItem.spaceID) {

      this._router.navigate([this.mainPathURL(), "s", spaceItem.spaceID]);
    }


  }




  nextSpaceEvent(currentSpace?: any) {
    this.loadingwrap = true;

    if (!this.path || !this.path.spacesMeta) {
      return;
    }

    if (!this.path.hasNextSpace(currentSpace)) {
      console.error("No next space to navigate");
      return;
    }

    const spaceItem = this.path.spacesMeta[this.path.spaceMetaIndex(currentSpace) + 1];

    if (spaceItem && spaceItem.spaceID) {
      this._router.navigate([this.mainPathURL(), "s", spaceItem.spaceID]);

    }


  }






  mainPathURL() {

    return `main/learning-paths/detail/${this.pathId}`;

  }

}

import { REQUEST_TYPES } from './../helper/user-profile.constant';
import { Injectable } from '@angular/core';
import { Adapter } from './../../../common/interfaces/adapter';
import { UserProfile, UserProfileAdapter } from './user-profile.model';



export class ConnectionRequest {
    id: string;
    type: string;
    date: Date;
    profile: UserProfile;
}


@Injectable({
    providedIn: 'root'
})
export class ConnectionRequestAdapter implements Adapter<ConnectionRequest> {

    adapt(requestObject: any): ConnectionRequest {

        let requestModel: ConnectionRequest = new ConnectionRequest();

         try {

            requestModel.id = (typeof requestObject.id === 'string') ? requestObject.id : null;
            requestModel.type = (typeof requestObject.type === 'string') ? requestObject.type : null;

            if ((typeof requestObject.date === 'string')) {
                requestModel.date = new Date(requestObject.date);
            }
            
            if (requestObject.requester){
                let profileAdapter = new UserProfileAdapter();
                requestModel.profile = profileAdapter.adapt(requestObject.requester);
                requestModel.profile.requestType = REQUEST_TYPES.REQUEST_TYPE_RECEIVED;                
                requestModel.type = REQUEST_TYPES.REQUEST_TYPE_RECEIVED;
            }

            if (requestObject.requestee){
                let profileAdapter = new UserProfileAdapter();
                requestModel.profile = profileAdapter.adapt(requestObject.requestee);                
                requestModel.profile.requestType = REQUEST_TYPES.REQUEST_TYPE_RAISED;
                requestModel.type = REQUEST_TYPES.REQUEST_TYPE_RAISED;
                
            }

            requestModel.profile.requestId = requestModel.id;


        } catch (error) {
            console.error("Unable to convert request data to profile", error);
        }
        return requestModel;

        

    }
    

}

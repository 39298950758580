export enum SpaceSortField {
    atoz = 'atoz',
    recent = 'recent',
}

export enum SpaceSortOrder {
    asc = 'asc',
    desc = 'desc'
}

export enum SpaceViewContext {
    Consumer = 'consumer',
    Editor  = 'editor'
}

export enum SpaceContext {
    MY_SPACE = 'my_space',
    USER_PROFILE_SPACE = 'user_profile_space',
    BYNDR_SPACE = 'byndr_space',
    CONNECTION_SPACE = 'connection_space',
    SHARED_SPACE =  'shared_space',
    PUBLIC_SPACE = 'public_space',
    BOOKMARKED_SPACE = 'bookmarked_space',
    SEARCH_RESULTS_SPACE = 'search_results_space',
    LEARNING_PATH_SPACE = 'learning_path_space',
    ADD_LEARNING_PATH_SPACE = 'add_learning_path_space',
    ADD_SPACE_TO_LEARNING_PATH = 'add_space_to_learning_path',
}

export enum SpaceBlockSaveAction {

    NEW_BLOCK = "new_block",
    MUTATE_BLOCK_NODE = "mutate_block_node",
    MUTATE_BLOCK_POSITION = "mutate_block_position",
    REMOVE_BLOCK = "remove_block"

}

export interface SpaceSort {
    sortBy: SpaceSortOrder,
    sortOrder: SpaceSortField
}

export enum SpaceType {
    learningspace = "learningspace",
    learningpath = "learningpath"
}

export const MAX_BLOCKS_PER_SPACE = 4;
export const BLOCK_SCALE : number = 20;
export const HOME_SPACE_RESULTS_PER_PAGE : number = 20;

export const SPACE_FILTER = {
    FILTER_TYPE : "type",
    FILTER_MYSPACES : "mine",
    FILTER_EXCLUDELPATHID: "excludeLPathID"
}

export const FILTER_TYPE = {
    SPACE: 'learningspace',
    PATH: 'learningpath'
}

export const FILTER_MYSPACES = 0;

export interface SpaceFilter {
    type: string;
    data: any;
}
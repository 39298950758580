import { Adapter } from './../../common/interfaces/adapter';
import { Injectable } from '@angular/core';
export class Tag {

    id: string;
    title: string;
    selected: boolean = false;

    get postDataForCreate() : any {

        return {
            name: this.title
        }
    }

}

@Injectable({
    providedIn: 'root'
})
export class TagAdapter implements Adapter<Tag> {
    adapt(data: any): Tag {

        let tag = new Tag();

        try {

            tag.id = (typeof data.id === 'string') ? data.id: null;

            if (typeof data === 'string') {
                tag.title = data;
            }
            else {
                tag.title = (typeof data.name === 'string') ? data.name: null;
            }
            
        } catch (error) {
            console.error("Unable to convert tag", error);
        }

        return tag;

    }
}

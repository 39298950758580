import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss']
})
export class InformationDialogComponent implements OnInit, OnDestroy {

  title: string;
  content: string;
  primaryButtonTitle: string = "OK"; 
  primaryButtonClickHandler : EventEmitter<any> = new EventEmitter<any>(); 

  constructor(public dialog: MatDialog) { }
  
  
  ngOnDestroy(): void {
    
    if (this.primaryButtonClickHandler)
      this.primaryButtonClickHandler.unsubscribe();
  }

  ngOnInit() {
  }

  

  primaryButtonClicked() {

    if (this.primaryButtonClickHandler != null) {
      this.primaryButtonClickHandler.emit(null);

      const dialogRef = this.dialog.getDialogById('information_dialog');
      if (dialogRef)
        dialogRef.close();

    }
    else{
      
    }

  }
  
}

import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocomplete, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { AuthenticationService } from "src/app/core/service/authentication.service";
import { UserProfileListResponse, UserProfileService } from "src/app/main/my-profile/data-providers/user-profile.service";
import { UserProfile } from "src/app/main/my-profile/models/user-profile.model";


@Component({
  selector: 'app-user-auto-complete',
  templateUrl: './user-auto-complete.component.html',
  styleUrls: ['./user-auto-complete.component.scss']
})

export class UserAutoCompleteComponent implements OnInit, OnDestroy {

  users: UserProfile[] = [];
  userID: string;
  @Input() viewMode: boolean = false;

  addOnBlur(event: FocusEvent) {
    const target: HTMLElement = event.relatedTarget as HTMLElement;
    if (!target || target.tagName !== 'MAT-OPTION') {
      const matChipEvent: MatChipInputEvent = {input: this.userInput.nativeElement, value : this.userInput.nativeElement.value};
      this.add(matChipEvent);
    }
  }

  @Output()
  async finalUsers(): Promise<UserProfile[]> {

    return this.users;

  }

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  userInputCtrl = new FormControl();
  filteredUsers: Observable<UserProfile[]>;
  allUsers: UserProfile[];
  loading: boolean = false;

  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private _profileService: UserProfileService,
      private _authenticationService: AuthenticationService) {
    if (!this.viewMode) {
      this.registerForInputValueChanges();
    }

  }
  ngOnDestroy(): void {
    if (this.userInputCtrl) {
      this.userInputCtrl.reset();
    }
  }

  registerForInputValueChanges() {
    this.filteredUsers = this.userInputCtrl.valueChanges.pipe(
      startWith(null),
      map(
        (keyword: string | null | UserProfile) => {

          if (keyword && (typeof keyword === 'string')) {
            return this._filterUsers(this._filter(keyword.trim()))
          }
          else if (keyword instanceof UserProfile) {
            return this._filterUsers(this.allUsers.slice());
          }
          else {
            if(!this.allUsers) {
              return [];
            }
            return this._filterUsers(this.allUsers.slice());
          }



        }));
  }


  ngOnInit(): void {
    const accessToken = this._authenticationService.accessToken;
    const _userID = this._authenticationService.userID(accessToken)
    if (_userID) {
      this.userID = _userID
    }

    this.getUsers();
    
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    console.log("Value ", value);

    // Check and add new interest
    if ((value || '').trim()) {

      const existingArray = this._filterUserWithKeyword(value);

      if (existingArray && existingArray.length > 0) {

        // Already exists. Nothing to do

      }
      else {
        const userArray = this._filter(value.trim(), true)

        if (userArray && userArray.length > 0) {
          this.users.push(userArray[0]);
        }
        else {
          // Nothing to add, User has selected from the list
        }
      }
    }



    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.userInputCtrl.setValue(null);
  }

  remove(u: UserProfile): void {
    const index = this.users.indexOf(u);

    if (index >= 0) {
      this.users.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {

    event.option.deselect();
    this.users.push(event.option.value);
    this.userInput.nativeElement.value = '';
    this.userInputCtrl.setValue(null);
  }

  private _filter(value: string, exactMatch = false): UserProfile[] {


    const filterValue = value.toLowerCase();

    const filteredArray = this.allUsers.filter(u => {

      if (exactMatch) {
        return u.displayName.toLowerCase() === filterValue;
      }
      else {
        return u.displayName.toLowerCase().includes(filterValue);
      }

    });

    return filteredArray;

  }


  private _filterUsers(filteredArray: UserProfile[]): UserProfile[] {

    const finalArray = filteredArray.filter(u => !this.users.find(x => x.id === u.id))

    return finalArray;

  }

  private _filterUserWithKeyword(keyword: string): UserProfile[] {

    const filterValue = keyword.toLowerCase();

    const filteredArray = this.users.filter(u => {

      return u.displayName.toLowerCase() === filterValue;

    });

    return filteredArray;


  }

  async getUsers() {
    this.loading = true;
    this.allUsers = [];

    try {
      let response: UserProfileListResponse = await this._profileService.getUserProfiles();
      if (response && response.success && response.profiles) {
        this.allUsers = response.profiles;       
      }
      this.loading = false;

    } catch (error) {
      this.allUsers = [];
      this.loading = false;
    }

  }

}

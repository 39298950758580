<div [ngClass]="[isDarkMode ? 'dark-theme' : 'light-theme', 'article-content-main']">

 <section class="loadingwrap" *ngIf="loadingwrap">
  <mat-spinner diameter="20"></mat-spinner>
 </section>


<ng-container *ngIf="space && nodes; else noData">
  <div class="article-header" fxLayout="row" fxLayoutAlign="start center" >
    <div class="popheader" fxLayout="row">
      

<section class="popTitle">{{_space.title}}</section>
<section class="middletitle"><app-metablock [setActiveIndex]="setIndex"  (nodeIconClickEvent)="showNode($event)" [space]="space" [fullpopup]="true"></app-metablock> 
  <h4 class="h4-black-20 mb-0" mat-dialog-title><span>{{node.title | titlecase}} </span> </h4>
</section>
      <section>
        <div class="theme-button">
          <button  class="btn-submit mat_btn_dark" (click)="toggleDarkMode()">


            <ng-container *ngIf="isDarkMode; else lightMode">
              <em title="switch to the light theme"  class="icon_dark_on"></em>
              <!-- <mat-icon title="switch to the light theme">light_mode</mat-icon> -->
            </ng-container>
            <ng-template #lightMode>
           <em title="switch to the dark theme" class="icon_light_on"></em>
              <!-- <mat-icon title="switch to the dark theme">dark_mode</mat-icon> -->
            </ng-template>
          </button>
        </div>
  <app-learning-space-action-bar [className]="classNames" [showNumbers]="true" [showViews]="false" [space]="space">
  </app-learning-space-action-bar>
  <div class="article-header-end" fxFlex fxLayoutAlign="end center">
    
    <app-library-node-actions *ngIf="!readonly && node" [node]="node"></app-library-node-actions>
   
    <button mat-icon-button color="primary" (click)="onCloseClick()">
      <mat-icon>close</mat-icon>
    </button>
  
  </div>

</section>
   




      <!-- <div fxFlex="initial" fxLayoutAlign="start center">
        <span class="p-small-grey14">
          {{node.createdAt | amDateFormat:'LLL'}}
          <ng-template [ngIf]="node.type === nodeType.folder">

          </ng-template>

          <ng-template [ngIf]="node.type === nodeType.file && node.fileInfo && (node.fileInfo.fileSize > 0)">
            &nbsp;&bull;
            {{node.fileInfo.fileSize | formatFileSize:1}}
          </ng-template>

          <ng-template [ngIf]="node.type === nodeType.link && node.urlInfo && node.urlInfo.meta.length > 0">
            &nbsp;&bull;
            <ng-container *ngFor="let metaInfo of node.urlInfo.meta ; let last = last">

              <ng-container *ngIf="last;else remainingMeta">
                {{metaInfo}}
              </ng-container>
              <ng-template #remainingMeta>
                {{metaInfo}} &nbsp;&bull;
              </ng-template>

            </ng-container>

          </ng-template>
        </span>
      </div> -->
    </div>
   
  </div>

</ng-container>
<ng-template #noData>


  

  <button class="closeBtn" mat-icon-button color="primary" (click)="onCloseClick()">
    <mat-icon>close</mat-icon>
  </button>
<section class="fetchData">Unable to fetch space.</section>

</ng-template>

  <div mat-dialog-content>
   <ng-container *ngIf="space && nodes">
    <div class="article-content">

      <ng-container [ngSwitch]="node.type">
        <ng-container *ngSwitchCase="nodeType.folder" [ngTemplateOutlet]="FolderViewer"> </ng-container>
        <ng-container *ngSwitchCase="nodeType.file" [ngTemplateOutlet]="FileViewer"></ng-container>
        <ng-container *ngSwitchCase="nodeType.link" [ngTemplateOutlet]="LinkViewer"></ng-container>
        <ng-container *ngSwitchCase="nodeType.activity" [ngTemplateOutlet]="ActivityViewer"></ng-container>

        <ng-template #FolderViewer>

        </ng-template>
        <ng-template #ActivityViewer>
          <app-questionnaire-run [activityId]="node.activityInfo.id"></app-questionnaire-run>
      </ng-template>

        <ng-template #FileViewer>
          <ng-container [ngSwitch]="node.fileInfo.viewer.viewerSource">
            <ng-container *ngSwitchCase="'google'" [ngTemplateOutlet]="GoogleViewer"></ng-container>
            <ng-container *ngSwitchCase="'office'" [ngTemplateOutlet]="OfficeViewer"></ng-container>
            <ng-container *ngSwitchCase="'image'" [ngTemplateOutlet]="ImageViewer"></ng-container>
            <ng-container *ngSwitchCase="'video'" [ngTemplateOutlet]="VideoViewer"></ng-container>

            <ng-template #GoogleViewer>
              <ngx-doc-viewer *ngIf="node.fileInfo" [url]="node.fileInfo.location" viewer="google"
                style="width:100%;height:100vh;"></ngx-doc-viewer>
            </ng-template>

            <ng-template #OfficeViewer>
              <ngx-doc-viewer *ngIf="node.fileInfo" [url]="node.fileInfo.location" viewer="office"
                style="width:100%;height:90vh;"></ngx-doc-viewer>
            </ng-template>

            <ng-template #ImageViewer>
              <div class="article-center">
                <img *ngIf="node.fileInfo" src={{node.fileInfo.location}} class="imageviewer" />
              </div>
            </ng-template>

            <ng-template #VideoViewer>
              <div class="article-center video-fullscreen"  *ngIf="node.fileInfo && !loading">
                <video width="100%" min-height="240" controls>
                  <source src={{node.fileInfo.location}}>
                </video>
              </div>
            </ng-template>

          </ng-container>
        </ng-template>

        <ng-template #LinkViewer>
          <ng-container *ngIf="node.urlInfo.content; else noContent">

            <article *ngIf="!loading">
              <div class="article-center">
                <div [ngClass]="node.urlInfo.articleClassForFullPreview"
                  [innerHTML]="node.urlInfo.content | safe: 'html'"></div>
              </div>
            </article>


          </ng-container>
          <ng-template #noContent>
            <div class="article-center cannot-show pt-20" fxLayout="column">
              <p class="pb-20 align-center">Cannot show preview of this link.</p>
              <button *ngIf="!loading" mat-raised-button (click)="openLink()" class="btn-submit">Open in new
                tab
                <mat-icon>open_in_new</mat-icon>
              </button>
            </div>
          </ng-template>


        </ng-template>

      </ng-container>

    </div>

    <button class="arrows next-icon" style="position: absolute; top: 48%; left: 20px"  aria-label="Previous" [disabled]="currentIndex == 0 || nodes.length == 0" (click)="previous()">
      <mat-icon title="Previous">arrow_back_ios_new</mat-icon>
    </button>

    <button class="arrows prev-icon" style="position: absolute; top: 48%; right: 20px"  aria-label="Next" [disabled]="(currentIndex + 1) === nodes.length" (click)="next()">
      <mat-icon title="Next">arrow_forward_ios_new</mat-icon>
    </button>
   
  </ng-container>

<div *ngIf="path" class="dailog-fixed-strip">  
  <div class="bottom-toolbar-container" *ngIf="tempSpaceId && path && path.spacesMeta">
      
    <mat-toolbar class="bottom-toolbar">

      <button mat-raised-button [disabled]="fetchingPath || !path.hasPreviousSpace(tempSpaceId)" [matTooltip]="path.hasPreviousSpace(tempSpaceId) ? path.spacesMeta[path.spaceMetaIndex(tempSpaceId) - 1].title : 'No Space'" matTooltipPosition="above" class="prev-btn" aria-label="Previous Space" (click)="previousSpaceEvent(tempSpaceId)">
        <mat-icon>chevron_left</mat-icon> Previous
      </button>
      <span class="toolbar-spacer"></span>
      <div class="lsp-title-block">
        <span class="lsp-title">{{path.title}}</span>
        <span class="lsp-path-count-subtitle">Space {{path.spaceMetaIndex(tempSpaceId) + 1}} of {{path.spacesMeta.length}}</span>
      </div>
      <span class="toolbar-spacer"></span>
        <button mat-raised-button [disabled]="fetchingPath || !path.hasNextSpace(tempSpaceId)" [matTooltip]="path.hasNextSpace(tempSpaceId) ? path.spacesMeta[path.spaceMetaIndex(tempSpaceId) + 1].title : 'No Space'" matTooltipPosition="above" class="btn-primary nxt-btn" aria-label="Next Space" (click)="nextSpaceEvent(tempSpaceId)">
          Next <mat-icon>chevron_right</mat-icon>
        </button>


    </mat-toolbar>
  
  </div>

</div>


    



  </div>
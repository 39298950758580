import { SpaceType } from './../../helper/space.constant';
import { SpaceTemplate } from './../../models/space-template.model';
import { InformationDialogComponent } from './../../../../shared/components/information-dialog/information-dialog.component';
import { SpaceService, LearningSpaceMutateResponse, SpaceResponse } from './../../data-providers/space.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { DESCRIPTION_MAX_LENGTH, SPACE_NAME_MAX_LENGTH } from 'src/app/common/constants';
import { LearningSpace } from '../../models/learning-space.model';
import { TagAutoCompleteComponent } from 'src/app/shared/components/tag-auto-complete/tag-auto-complete.component';
import { Tag } from 'src/app/shared/models/tag.model';

@Component({
  selector: 'app-edit-learning-space-dialog',
  templateUrl: './edit-learning-space-dialog.component.html',
  styleUrls: ['./edit-learning-space-dialog.component.scss']
})
export class EditLearningSpaceDialogComponent implements OnInit, OnDestroy {

  fetchingSpace: boolean = false;
  editLearningSpaceForm: any | undefined = FormGroup;
  loading: boolean = false;
  mutateInProgress: boolean = false;
  spaceNameMaxLength = SPACE_NAME_MAX_LENGTH;
  descriptionMaxLength = DESCRIPTION_MAX_LENGTH;
  templateId: string;
  color: ThemePalette = 'primary';

  @Input() spaceId: string | undefined;
  spaceType: SpaceType = SpaceType.learningspace;
  displayTitle: string = "Learning Space";

  space: LearningSpace | undefined;
  @Output() editSpaceEvent = new EventEmitter<LearningSpace>();
  @ViewChild("tagField", { static: false }) tagField: TagAutoCompleteComponent;



  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _spaceService: SpaceService) { }



  ngOnInit(): void {
    
    if (this.spaceId) {
      this.getLearningSpace(this.spaceId);
    }

  }

  async getLearningSpace(_spaceId: string | null) {
    if (!_spaceId) {
      return;
    }

    this.fetchingSpace = true;

    try {
      //TODO: Replace true with authentication service is user logged in 
      let response: SpaceResponse = await this._spaceService.getSpace(_spaceId, true, this.spaceType);

      if (response.success) {
        const _space: LearningSpace | undefined = response.space;
        if (_space) {
          this.space = _space;
          this.createEditForm();
          this.updateFormValue();
        }
      }

      this.fetchingSpace = false;

    } catch (error) {
      this.space = undefined;
      this.fetchingSpace = false;
    }
  }

  createEditForm() {

    this.editLearningSpaceForm = new FormGroup({
      spaceName: new FormControl('', [Validators.required, Validators.maxLength(SPACE_NAME_MAX_LENGTH)]),
      description: new FormControl('', [Validators.maxLength(DESCRIPTION_MAX_LENGTH)]),
    });

  }

  updateFormValue() {
    if (!this.space) 
      return;

    this.editLearningSpaceForm.patchValue({
      spaceName: this.space.title,
      description: this.space.description
    })
  }


  async onSubmit(post: any) {
    if (!this.space || this.editLearningSpaceForm.invalid) {
      return;
    }

    const tags: Tag[] = await this.tagField.finalTags();
    post.tags = tags;
  


    this.space.title = post.spaceName;
    this.space.description = post.description;
    this.space.tags = post.tags;

    this.mutateSpace(this.space);


  }

  async mutateSpace(_space: LearningSpace) {
    this.mutateInProgress = true;

    try {
      let response: LearningSpaceMutateResponse = await this._spaceService.updateSpace(_space);
      if (response.success) {
        const receivedSpace: LearningSpace | undefined = response.space;

        if (receivedSpace) {

          this.editSpaceEvent.next(receivedSpace);
          this.closeLearningSpaceDialog();

          this.showSnackBar(`Your ${this.displayTitle} has been updated.`, "OK");

        }
        else {
          this.showErrorDialog(`Unable to update ${this.displayTitle}`);
        }
      }
      else {
        this.showErrorDialog("Oops,something went wrong. Please try again");
      }

      this.mutateInProgress = false;


    } catch (error) {
      console.log(`Unable to update ${this.displayTitle}`, error);
      this.mutateInProgress = false;
      this.showErrorDialog("Oops,something went wrong. Please try again");
    }
  }

  showErrorDialog(error: any) {

    const dialogRef = this._dialog.open(InformationDialogComponent, {
      width: '300px',
      id: 'information_dialog'
    })

    dialogRef.componentInstance.content = error



  }

  get remainingLengthForSpaceName(): number {

    if (this.editLearningSpaceForm.get('spaceName').value) {
      return SPACE_NAME_MAX_LENGTH - this.editLearningSpaceForm.get('spaceName').value.length;
    }
    return SPACE_NAME_MAX_LENGTH;

  }

  get remainingLengthForDescription(): number {

    if (this.editLearningSpaceForm.get('description').value) {
      return DESCRIPTION_MAX_LENGTH - this.editLearningSpaceForm.get('description').value.length;
    }
    return DESCRIPTION_MAX_LENGTH;

  }

  templatesLoaded() {
    // this.loading = false;
  }

  templateSelected(_template: SpaceTemplate) {

    this.templateId = _template.id;

  }

  showSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 10000 });
  }


  closeLearningSpaceDialog() {
    const dialogRef = this._dialog.getDialogById('edit_learning_space_dialog');
    if (dialogRef)
      dialogRef.close();
  }


  ngOnDestroy(): void {
    if (this.editSpaceEvent)
      this.editSpaceEvent.unsubscribe();
  }


}

<ng-container *ngIf="!loading; else usersSkeletonLoading">
    <ng-container *ngIf="viewMode; else editMode">
        <mat-chip-list #chipList aria-label="User Selection">
            <mat-chip *ngFor="let u of users" [selectable]="false" [removable]="false">
                {{u.displayName}}
            </mat-chip>
        </mat-chip-list>
    </ng-container>
    <ng-template #editMode>
        <mat-form-field appearance="outline">
            <mat-label></mat-label>
            <mat-chip-list #chipList aria-label="User Selection">
                <mat-chip *ngFor="let u of users" [selectable]="selectable" [removable]="removable"
                    (removed)="remove(u)">
                    {{u.displayName}}
                    <mat-icon matChipRemove *ngIf="removable">close</mat-icon>
                </mat-chip>

                <input placeholder="Select Users" #userInput [matChipInputAddOnBlur]="false" (blur)="addOnBlur($event)"
                    [formControl]="userInputCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option class="user-auto-complete-option" *ngFor="let profileObject of filteredUsers | async" [value]="profileObject">
                    <app-profile-image [viewMode]='true' [userDetails]='profileObject' class="cursor-pointer"
                        [userID]='userID'>
                    </app-profile-image>
                    <span class="mb-10">{{profileObject.displayName}}</span>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </ng-template>
</ng-container>
<ng-template #usersSkeletonLoading>
    <div [ngClass]="viewMode === false? 'pt-20': ''">
        <ngx-skeleton-loader count="10" [theme]="{ 
        height: '25px',
        width: '100px',
        marginBottom: '10px',
        marginRight: '10px',
        borderRadius: '16px'
      }"></ngx-skeleton-loader>
    </div>
</ng-template>



<!-- <div class="share-autocomplete pl-20 pr-20 pt-20">
    <mat-form-field appearance="outline">
        <mat-label>Find People</mat-label>
        <input type="text" placeholder="Search for people" aria-label="search for people" matInput
            [formControl]="myControl" [matAutocomplete]="auto">
        <span matSuffix>
            <mat-icon>search</mat-icon>
        </span>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div> -->
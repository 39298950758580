<div class="library-main w-100">
    <div class="library-welcome-card1">
        <div class="library-box">
            <div class="byndr_logo align-center"><span class="icon byndr-logo"></span></div>
            <div *ngIf="!isSaveCompleted">
            <div class="in-ls"><img class="inn-img" src="{{getFaviconUrl(tabUrl)}}"><input type="text"
                    [(ngModel)]="tabUrl" class="myinput align-center"></div>
            <div class="mat-lib">
                <div class="library-icon" *ngIf="parentTitle == ''"><img src="../../assets/images/rawimages/library.png"> <span
                        class="innericon"><b>Library</b></span>
                </div>
                <div *ngIf="parentTitle !== ''"> 
                    
                    <img (click)="selectBackFolder()" style="cursor: pointer;width: 24px;height:24px;margin-top: -3px;"
                        src="../../assets/images/icons/round_home_black_24dp.png">
                    <span class="innericon" matTooltipPosition="above"
                    [matTooltip]="parentTitle"><b>{{  (parentTitle.length>26)? (parentTitle | slice:0:25)+ "...":(parentTitle) }}</b></span>
                </div>
            </div>
            <div class="info_block_parent" style="display: block !important;">
                <div class="col-12 no-folders-block info_block withoutFolders text-center" *ngIf="noNodes">   <!--&& parentTitle == ''-->
                    
                    <span class="icon icon-library-grey"></span>
                    <span class="icon icon-library-border-grey"></span>     
                    <h6 style="color:black">
                        You have <br />
                        No Folders in Library
                    </h6>
                    <p class="changes">
                        Create a New Folder <br /> or <br /> Save it to Library directly.
                    </p>
                </div>
                <!-- ÷*ngIf="fetchingNodes" -->
                <div class="p-10 text-center" fxFlex="initial" fxLayoutAlign=" center"  style='width:100%;margin-left:75px;' *ngIf="fetchingNodes">
                    <mat-spinner mode="indeterminate"></mat-spinner>
                    
                </div>
                <div *ngIf="!noNodes" style='width:100%;overflow: auto;height: 229px;'>
                    <mat-list class="align-left" style='width:100%;margin-left: -7px;'>

                        <mat-list-item  class="divHover" *ngFor="let node of nodes" (click)="selectParent(node)">
                            <div class="folderhover" (click)="selectParent(node)">
                               <div class="textTruncate"> <img src="../../assets/images/rawimages/folder.png">
                                <span class="innericon" style="white-space: pre-wrap "
                                matTooltipPosition="above"
                                [matTooltip]="node.title"
                                >{{  (node.title.length>26)? ((node.title | slice:0:25)+"..."):(node.title) }}</span>
                                <!-- <span
                                    class="innericon">{{node.title.substr(0,25)}}</span> <span *ngIf="node.title.length>25">...</span> -->
                                </div>
                                 
                            </div>
                            <div class="imgHover">    <img class="align-right" src="../../assets/images/rawimages/ic_forward_arrow.png"></div>
                        </mat-list-item>
                    </mat-list>
                </div>
            </div>

            
            <div class="cr-new">
                <p class="lb-n" *ngIf="showInputBox == true">Create New Folder</p>
                <input type="text" [(ngModel)]="folderTitle" class="myinputinner align-center p-3"
                    *ngIf="showInputBox == true" maxlength="60" (ngModelChange)="lengthCalc()"><span class="ch-60"
                    *ngIf="showInputBox == true">{{stringLength}}</span></div>
            <div fxLayoutAlign="end start" class="p-20 buttons-section sectionsWidth">
                <!-- <img class="cr-img" src="../../assets/images/rawimages/iccreate.png"> -->
                <button [disabled]="isButtonDisabled" class="btn-grey1 noBorder" (click)="showInput()" *ngIf="showInputBox == false"><span><img class="cr-img" src="../../assets/images/rawimages/iccreate.png"></span>Create</button>
                <button [disabled]="isButtonDisabled" class="btn-primary" style="margin-right: 20px;" (click)="createDialog()" *ngIf="showInputBox == true">Create</button>
                <button [disabled]="isButtonDisabled" mat-raised-button class="btn-primary" (click)="createDialog()"
                    *ngIf="showInputBox == false" (click)="saveUrl()">Save </button>
                <button [disabled]="isButtonDisabled" mat-raised-button class="btn-grey1 noBorder pl-2" (click)="cancelInputBox()"
                    *ngIf="showInputBox == true">Cancel</button>
                   <mat-spinner *ngIf="isButtonDisabled" [diameter]="20"  style="display: inline;margin-top: 8px;margin-left: 3px; color:white; padding-left:10px;"></mat-spinner>  

            </div>
            </div>
            <div *ngIf="isSaveCompleted">
                <div class="w-100" fxLayout="row wrap" fxLayoutWrap="wrap" style="text-align: center;">
                    <div class="library-welcome-card">
                        <div class="library-box" style="margin-left: 70px;margin-top:70px;">
                            
                            <img class="align-center li-i" src="../../assets/images/icons/success-24-64.png">
                            <h5 class="align-center" style="margin-top: 10px;">URL has been saved</h5>
                           
                         
                        
                    </div>
                   </div></div>
            </div>
        </div>
        

    </div>
</div>
<!--<div class="col-sm-12">
    <input matInput placeholder="Activity name" value="">
</div>
<mat-grid-list cols="1" rowHeight="2:1" fxLayout="row" fxLayoutAlign="start center">
    <div class="first">
        <button mat-raised-button class="btn-primary" color="primary" (click)="addQuestion()">Add Question</button>
    </div>
  </mat-grid-list>-->

<ng-container *ngIf="loading; else QF"> Loading... </ng-container>

<ng-template #QF>
  <div class="row rowheading">
    <div class="sc-a bt-l">
      <span class="icon icon-url-activity-with-bg"></span>
      <input
        class="input-sc box"
        matInput
        placeholder="Activity Name"
        value="{{ activityName }}"
        #activityNameView
      />
    </div>

    <!-- <div class="sc-a">
      <button
        mat-raised-button
        class="btn-primary bt-r"
        color="primary"
        (click)="addQuestion()"
      >
        Add Question
      </button>
    </div> -->
  </div>
  <form [formGroup]="questionnarireForm">
    <ng-container formArrayName="questions">
      <mat-tab-group
        [selectedIndex]="selectedIndex"
        class="noborder"
        #stepper
        (focusChange)="tabFocusChange($event)"
        (selectedTabChange)="tabChange($event)"
      >
        <ng-container
          *ngFor="
            let _ of questionGroup.controls;
            index as i;
            first as first;
            last as last
          "
        >
          <mat-tab>
            <ng-template mat-tab-label>
              <button mat-stroked-button>
                {{ i + 1
                }}<span class="star">{{ getQuestionGroupErrors(i) }}</span>
                <mat-icon
                  (click)="$event.stopPropagation(); removeQuestionEvent(i)"
                  class="remove-question"
                  >highlight_off</mat-icon
                >
              </button>
            </ng-template>
            <ng-container [formGroupName]="i">
              <!-- <div class="summary-panel"><ul><li>point one</li><li>point one</li><li>point one</li><li>point one</li></ul></div> -->

              <div style="width: 100%">
                <mat-form-field class="create_question" appearance="fill">
                  <input
                    type="text"
                    matInput
                    formControlName="question"
                    placeholder="Start typing your question here..."
                  />
                  <mat-error
                    *ngIf="
                      getQuestionFormControl(i)?.dirty &&
                      getQuestionFormControl(i)?.hasError('required')
                    "
                  >
                    {{ getErrorMessage("INVALID_QUESTION") }}
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- <label id="question-type">Type</label>
                            <br /> -->
              <!-- <mat-radio-group aria-labelledby="question-type-label" style="width: 100%;"
                                formControlName="questionType" class="example-radio-group"
                                (change)="questionTypeChanged($event.value, i)">
                                <mat-radio-button class="example-radio-button rdcolor" name="questionType"
                                    [disabled]="questionTypeObj.label=='TEXTBOX'" [value]="questionTypeObj.label"
                                    *ngFor="let questionTypeObj of questionTypes">
                                    {{questionTypeObj.displayLabel}}
                                </mat-radio-button>
                            </mat-radio-group> -->

              <ng-container formArrayName="options">
                <div
                  *ngIf="
                    getQuestionTypeValue(i) &&
                    getQuestionTypeValue(i) != 'TEXTBOX'
                  "
                >
                  <ng-container
                    *ngIf="
                      getQuestionGroupOptions(i).controls &&
                        getQuestionGroupOptions(i).controls.length > 0;
                      else NoOptions
                    "
                  >
                    <ng-container
                      *ngFor="
                        let oc of getQuestionGroupOptions(i).controls;
                        index as j;
                        first as f;
                        last as lst
                      "
                    >
                      <div class="flexBlock">
                        <div>
                          <ng-container [formGroupName]="j">
                            <!-- <div>{{ (j+1) | numberToAlphabet}}</div> -->
                            <div class="optionKey">
                              <span>
                                {{
                                  getOptionKey(getQuestionGroupOptions(i), j)
                                }}
                              </span>
                            </div>
                            <div class="inputWrap">
                              <mat-form-field appearance="outline">
                                <input
                                  type="text"
                                  matInput
                                  required="true"
                                  formControlName="label"
                                  placeholder="Option*"
                                />
                                <!-- <mat-error *ngIf="oc.hasError('required')">
                            Option is <strong>required</strong>
                          </mat-error> -->
                                <!-- <span *ngIf="getQuestionGroupOption(i, j)?.touched && getQuestionGroupOption(i, j)?.hasError('required')">
                            {{ getErrorMessage('INVALID_OPTION') }}
                          </span> -->
                                <!-- <p> 
                                                  <span *ngIf="questionnarireForm.status != 'VALID'"> Options are mandatory</span>
                                              </p> -->
                              </mat-form-field>
                            </div>
                            <div class="actions">
                              <mat-checkbox
                                [checked]="
                                  checkIsCorrect(getQuestionGroupOptions(i), j)
                                "
                                (change)="answerChange($event.checked, i, j)"
                              >
                              </mat-checkbox>
                            </div>
                          </ng-container>
                        </div>
                        <div class="deletewrap">
                          <button
                            mat-icon-button
                            class="btremove"
                            (click)="
                              removeOptionGroup(
                                getQuestionGroupOptions(i),
                                j,
                                i
                              )
                            "
                            aria-label="Remove Option"
                          >
                            <mat-icon>delete_outlined</mat-icon>
                          </button>
                        </div>
                      </div>
                      <span
                        class="custom-error"
                        *ngIf="
                          lst &&
                          questionGroup.controls[i].errors?.invalid_options
                        "
                      >
                        {{ getErrorMessage("PLEASE_ADD_ATLEAST_TWO_OPTIONS") }}
                      </span>
                      <span
                        class="custom-error"
                        *ngIf="
                          getQuestionGroupOption(i, j)?.dirty &&
                          getQuestionGroupOption(i, j)?.hasError('required')
                        "
                      >
                        {{ getErrorMessage("INVALID_OPTION") }}
                      </span>
                      <span
                        class="custom-errorba"
                        *ngIf="
                          lst &&
                          questionGroup.controls[i].errors
                            ?.invalid_correct_answer
                        "
                      >
                      <mat-error>
                        {{ getErrorMessage("CHOOSE_CORRECT_ANSWER") }}</mat-error>
                      </span>
                    </ng-container>
                  </ng-container>
                  <ng-template #NoOptions>
                    <span
                      class="custom-error"
                      *ngIf="questionGroup.controls[i].errors?.invalid_options"
                    >
                      {{ getErrorMessage("PLEASE_ADD_ATLEAST_TWO_OPTIONS") }}
                    </span>
                  </ng-template>
                  <div class="buttons-section">
                    <button
                      mat-button
                      class="btn-primary btn-cl"
                      (click)="addOptiontoGroup(getQuestionGroupOptions(i))"
                    >
                      <mat-icon>add</mat-icon>New Option
                    </button>
                  </div>
                </div>
              </ng-container>
              <div style="width: 100%" class="answer_wrap">
                <label>Correct Answer: {{ getCorrectAnswers(i) }}</label>

                <mat-form-field class="answer_key" appearance="fill">
                  <textarea
                    matInput
                    matInput
                    formControlName="answerKey"
                    placeholder="Enter the reason for correct answer"
                  ></textarea>
                  <mat-error
                    *ngIf="
                      getAnswerKeyFormControl(i)?.dirty &&
                      getAnswerKeyFormControl(i)?.hasError('required')
                    "
                  >
                    {{ getErrorMessage("INVALID_ANSWER_KEY") }}
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-container>

            <!-- <button mat-button *ngIf='!first' matStepperPrevious>PREVIOUS</button>
                        <button mat-button matStepperNext>NEXT</button> -->
          </mat-tab>
        </ng-container>
        <mat-tab class="addIcon" disabled>
          <ng-template mat-tab-label>
            <button
              mat-raised-button
              class="btn-primary addBtn bt-r"
              color="primary"
              (click)="addQuestionEvent()"
            >
              <mat-icon>add</mat-icon>
            </button>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
    <!-- <div class="p-20 buttons-section">
            <div fxFlex fxLayoutAlign="end center">
                <button mat-button class="btn-primary btn-cl" (click)="submit()">Create Activity</button>
            </div>
        </div> -->
  </form>
</ng-template>

<div class="publish-dialog">
    <div class="pb-20" fxLayout="row" fxLayoutAlign="start start">
      <div fxFlex="initial" fxLayoutAlign="start start">
        <h3 class="h3-24-black">Are you sure you want to delete this {{displayTitle | lowercase}}?</h3>
      </div>
      <div fxFlex fxLayoutAlign="end start">
        <mat-icon class="close-icon" mat-dialog-close>close</mat-icon>
      </div>
    </div>
    <!-- <p class="p-small-black15 pb-20">Contents in this learning space will be visible to:</p>
    <div class="publish-list">
      <ul>
        <li>
          <mat-icon>check_circle</mat-icon>
          My Connections
        </li>
        <li>
          <mat-icon>check_circle</mat-icon>
          Shared by Me
        </li>
        <li>
          <mat-icon>check_circle</mat-icon>
          Shared by my connections
        </li>
      </ul>
    </div> -->
    <mat-dialog-actions [ngClass]="[submissionInProgress? 'show-loading' : '', 'pb-20']" align="center">
      <button mat-raised-button [disabled]="submissionInProgress" class="btn-grey" mat-dialog-close>Cancel</button>
      <button mat-raised-button [disabled]="submissionInProgress" class="btn-delete" cdkFocusInitial (click)="submitTapped()">Delete</button>
      <mat-spinner *ngIf="submissionInProgress" [color]="color" diameter="25" strokeWidth="3">
      </mat-spinner>
    </mat-dialog-actions>
  
  </div>